import { Flex, Heading } from "@chakra-ui/react";
import React, { FC } from "react";

export const TasksPage: FC = () => {
  return (
    <Flex width="100%" direction="column">
      <Heading>Hi there I am Tasks Page</Heading>
    </Flex>
  );
};
